import React from 'react';
import { graphql } from 'gatsby';
import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';

import ContentBlock from '../blocks/ContentBlock';
import { PortraitImage } from '../blocks/PortraitImage';
import { ContactForm } from 'Blocks/ContactForm';
import { DefaultLayout } from '../layouts/Default';

const KontaktPage = ({ data, location }) => {
  const { site, content } = data;
  const { opengraph, metadata, contentblock1, contentblock2 } = content;
  const { siteMetadata } = site;
  return (
    <DefaultLayout>
      <ContentBlock data={contentblock1} />
      <PortraitImage />
      <ContactForm />
      <ContentBlock data={contentblock2} />
      <Metadata
        data={metadata}
        opengraphData={opengraph}
        defaultTitle={siteMetadata.title}
        canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
        path={location.pathname}
      />
    </DefaultLayout>
  );
};

export default KontaktPage;

export const KontaktPageQuery = graphql`
  query KontaktPageQuery {
    site: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    content: cockpitRegionKontakt {
      id
      contentblock1 {
        title
        published
        text
        align
        columns
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      contentblock2 {
        title
        published
        text
        align
        columns
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          childImageSharp {
            gatsbyImageData(width: 1200, height: 630)
          }
        }
      }
    }
  }
`;
