import React from 'react';
import PropTypes from 'prop-types';

import { ContactForm as ContactFormComponent } from 'Components/ContactForm';
import { Section } from 'Components/Section';

import * as styles from './ContactForm.module.css';

ContactForm.propTypes = {};
ContactForm.defaultProps = {};

export function ContactForm(props) {
  return (
    <Section title="Senden Sie uns eine e-mail:" className="defaultWidth">
      <ContactFormComponent layout="wide" />
    </Section>
  );
}
